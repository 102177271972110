// extracted by mini-css-extract-plugin
export var blue = "ComparisonTableModule-module--blue--c49cc";
export var button = "ComparisonTableModule-module--button--dec7b";
export var container = "ComparisonTableModule-module--container--81e72";
export var contentContainer = "ComparisonTableModule-module--contentContainer--266f8";
export var exportTable = "ComparisonTableModule-module--exportTable--7a859";
export var grapefruit = "ComparisonTableModule-module--grapefruit--022ad";
export var green = "ComparisonTableModule-module--green--4370d";
export var paragraph = "ComparisonTableModule-module--paragraph--bb05d";
export var reviewStarsContainer = "ComparisonTableModule-module--reviewStarsContainer--ab587";
export var section = "ComparisonTableModule-module--section--11d9b";
export var sectionWithNoPadding = "ComparisonTableModule-module--sectionWithNoPadding--c8220";
export var sectionWithNoPaddingMobile = "ComparisonTableModule-module--sectionWithNoPaddingMobile--a1010";
export var table = "ComparisonTableModule-module--table--65232";
export var title = "ComparisonTableModule-module--title--6ee06";
export var transparentPrice = "ComparisonTableModule-module--transparentPrice--17f93";