// extracted by mini-css-extract-plugin
export var blue = "ComparisonTable-module--blue--c6123";
export var characteristic = "ComparisonTable-module--characteristic--ffc5b";
export var characteristicCell = "ComparisonTable-module--characteristicCell--16370";
export var characteristicWrapper = "ComparisonTable-module--characteristicWrapper--aa7b4";
export var characteristicsTitlesContainer = "ComparisonTable-module--characteristicsTitlesContainer--b0179";
export var container = "ComparisonTable-module--container--c156b";
export var divider = "ComparisonTable-module--divider--8a8cc";
export var entitiesContainer = "ComparisonTable-module--entitiesContainer--26e94";
export var entityColumn = "ComparisonTable-module--entityColumn--d5325";
export var grapefruit = "ComparisonTable-module--grapefruit--e29ca";
export var green = "ComparisonTable-module--green--57f13";
export var header = "ComparisonTable-module--header--4940f";
export var headerCell = "ComparisonTable-module--headerCell--03499";
export var title = "ComparisonTable-module--title--a929d";
export var titleCell = "ComparisonTable-module--titleCell--b7273";