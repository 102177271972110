import React from 'react';
import { Link } from 'gatsby';
import { clsx } from '../../utils/utils';
import ButtonLink from './ButtonLink';

import * as styles from './FeaturedCard.module.scss';
import Button from './Button';

export interface FeaturedCardProps {
  className?: string;
  imageUrl: string;
  alt?: string;
  buttonText: string;
  buttonUrl: string;
  cardsPerRowClass: 'cardsPerRow1' | 'cardsPerRow2' | 'cardsPerRow3' | 'cardsPerRow4';
}

const FeaturedCard = ({
  className,
  imageUrl,
  alt,
  buttonText,
  buttonUrl,
  cardsPerRowClass,
}: FeaturedCardProps): React.ReactElement => {
  return (
    <div className={clsx(className, styles.card)}>
      <Link to={buttonUrl}>
        <div
          className={clsx(
            styles.imageContainer,
            (cardsPerRowClass === 'cardsPerRow1' && styles.cardsPerRow1) ||
              (cardsPerRowClass === 'cardsPerRow2' && styles.cardsPerRow2) ||
              (cardsPerRowClass === 'cardsPerRow3' && styles.cardsPerRow3) ||
              (cardsPerRowClass === 'cardsPerRow4' && styles.cardsPerRow4),
          )}
        >
          <img className={styles.image} src={imageUrl} alt={alt || ''} />
          <Button className={styles.button} color={'dark-blue'} rightIcon={'arrow'}>
            {buttonText}
          </Button>
        </div>
      </Link>
    </div>
  );
};

export default FeaturedCard;
