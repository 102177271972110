// extracted by mini-css-extract-plugin
export var button = "FeaturedCardsModule-module--button--6b4fb";
export var cards = "FeaturedCardsModule-module--cards--6044f";
export var cardsContainer = "FeaturedCardsModule-module--cardsContainer--cac78";
export var cardsPerRow2 = "FeaturedCardsModule-module--cardsPerRow2--14e97";
export var cardsPerRow3 = "FeaturedCardsModule-module--cardsPerRow3--cfdeb";
export var cardsPerRow4 = "FeaturedCardsModule-module--cardsPerRow4--675ef";
export var container = "FeaturedCardsModule-module--container--ac98f";
export var content = "FeaturedCardsModule-module--content--39ee1";
export var contentContainer = "FeaturedCardsModule-module--contentContainer--604fe";
export var paragraph = "FeaturedCardsModule-module--paragraph--00f95";
export var section = "FeaturedCardsModule-module--section--ef721";
export var sectionWithNoPadding = "FeaturedCardsModule-module--sectionWithNoPadding--26cb3";
export var title = "FeaturedCardsModule-module--title--ce380";