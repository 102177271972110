// extracted by mini-css-extract-plugin
export var approved = "EntityCard-module--approved--25fc9";
export var approvedBox = "EntityCard-module--approvedBox--8010e";
export var button = "EntityCard-module--button--d7962";
export var container = "EntityCard-module--container--fdee2";
export var icon = "EntityCard-module--icon--5185b";
export var image = "EntityCard-module--image--43189";
export var imageContainer = "EntityCard-module--imageContainer--b0342";
export var introText = "EntityCard-module--introText--90fba";
export var name = "EntityCard-module--name--1f18d";
export var nameAndApprovedLine = "EntityCard-module--nameAndApprovedLine--ef5d4";
export var reviewStarsContainer = "EntityCard-module--reviewStarsContainer--be4bc";