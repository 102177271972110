import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { LawyerCharacteristics } from '../../graphql-fragments/Lawyer';
import { BankCharacteristics } from '../../graphql-fragments/Bank';
import { FundCharacteristics } from '../../graphql-fragments/Fund';
import EntityCard from '../ui/EntityCard';

import * as styles from './EntityCardsModule.module.scss';
import { CommonModuleProps } from '../../templates/Page';
import { sortBy } from '../../utils/nodash';
import {
  BANK_PREFIX_URL,
  ENTITY_TYPES_WITH_REVIEWS,
  FUND_PREFIX_URL,
  LAWYER_PREFIX_URL,
  REAL_ESTATE_PROJECT_PREFIX_URL,
} from '../../constants';
import { clsx, urlJoin } from '../../utils/utils';
import { initYotpoWidgets } from '../../utils/yotpo';
import { RealEstateProjectCharacteristics } from '../../graphql-fragments/RealEstateProject';

export const EntityCardsModuleFragment = graphql`
  fragment EntityCardsModule on SanityEntityCardsModule {
    entity
    title
    text
  }
`;

export type EntityCardsModuleProps = {
  entity: 'lawyer' | 'bank' | 'fund' | 'realEstateProject';
  title: string;
  text: string;
  onLoadMoreClick: () => void;
};

interface QueryData {
  allSanityLawyer: {
    nodes: Array<LawyerCharacteristics>;
  };
  allSanityBank: {
    nodes: Array<BankCharacteristics>;
  };
  allSanityFund: {
    nodes: Array<FundCharacteristics>;
  };
  allSanityRealEstateProject: {
    nodes: Array<RealEstateProjectCharacteristics>;
  };
}

export function getModuleBgColor(props: EntityCardsModuleProps) {
  return 'white';
}

const EntityCardsModule = (
  props: EntityCardsModuleProps & CommonModuleProps,
): React.ReactElement => {
  const { entity: entityType, title, text, previousModuleBgColor } = props;
  const data: QueryData = useStaticQuery(
    graphql`
      {
        allSanityLawyer {
          nodes {
            ...Lawyer
          }
        }
        allSanityBank {
          nodes {
            ...Bank
          }
        }
        allSanityFund {
          nodes {
            ...Fund
          }
        }
        allSanityRealEstateProject {
          nodes {
            ...RealEstateProject
          }
        }
      }
    `,
  );

  useEffect(() => {
    initYotpoWidgets();
  }, []);

  let entityList: Array<
    | LawyerCharacteristics
    | BankCharacteristics
    | FundCharacteristics
    | RealEstateProjectCharacteristics
  >;

  if (entityType === 'lawyer') {
    entityList = data.allSanityLawyer.nodes;
  } else if (entityType === 'bank') {
    entityList = data.allSanityBank.nodes;
  } else if (entityType === 'fund') {
    entityList = data.allSanityFund.nodes;
  } else if (entityType === 'realEstateProject') {
    entityList = data.allSanityRealEstateProject.nodes;
  } else {
    throw new Error('Got unknown entityType: ' + entityType);
  }

  const prefixUrlByEntityType = {
    lawyer: LAWYER_PREFIX_URL,
    bank: BANK_PREFIX_URL,
    fund: FUND_PREFIX_URL,
    realEstateProject: REAL_ESTATE_PROJECT_PREFIX_URL,
  };

  function sortApprovedEntitiesFirst<
    T extends
      | LawyerCharacteristics
      | BankCharacteristics
      | FundCharacteristics
      | RealEstateProjectCharacteristics,
  >(entityList: T[]) {
    return sortBy(entityList, entity => (entity.approved ? 0 : 1));
  }

  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
      )}
    >
      <div className={styles.container}>
        <div data-aos="fade-right" data-aos-delay="0" data-aos-offset="0" data-aos-duration="1000">
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.text}>{text}</p>
        </div>
        <div
          className={styles.contentContainer}
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-offset="0"
          data-aos-duration="1000"
        >
          <div className={styles.cardsContainer}>
            {sortApprovedEntitiesFirst(entityList).map((entity, i) => (
              <EntityCard
                key={i}
                entityId={entity._id}
                hasReviews={ENTITY_TYPES_WITH_REVIEWS.includes(entityType)}
                name={entity.name}
                approved={entity.approved}
                introText={entity.introText}
                pageUrl={urlJoin(prefixUrlByEntityType[entityType], entity.slug.current)}
                imageUrl={
                  (entityType === 'lawyer'
                    ? (entity as LawyerCharacteristics).image
                    : (
                        entity as
                          | BankCharacteristics
                          | FundCharacteristics
                          | RealEstateProjectCharacteristics
                      ).logoImage
                  ).asset.url + '?w=300&h=300&fit=max'
                }
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EntityCardsModule;
