export function initYotpoWidgets() {
  let attemptsLeft = 10;
  function attemptInitWidgets() {
    attemptsLeft--;
    // @ts-ignore
    if (typeof yotpo !== 'undefined') {
      console.log('Init widgets');
      // @ts-ignore
      yotpo.refreshWidgets();
    } else {
      if (attemptsLeft > 0) {
        setTimeout(attemptInitWidgets, 1000);
      } else {
        throw new Error('Failed to init Yotpo widgets');
      }
    }
  }
  console.log('Trying to init widgets');
  attemptInitWidgets();
}

export function getNumericIDFromSanityID(sanityID: string) {
  const sanityIDWithoutDrafts = sanityID.split('.').slice(-1)[0];
  return parseInt(sanityIDWithoutDrafts.split('-')[0], 16);
}
