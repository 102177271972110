import React from 'react';
import { clsx } from '../../utils/utils';

import * as styles from './ComparisonTable.module.scss';
export interface ComparisonTableProps {
  className?: string;
  characteristicsConfig: Array<{
    title?: string;
    characteristicKey: string;
    renderFunc?: (characteristic: string | number | boolean) => React.ReactNode;
  }>;
  scrollBarColor: string;
  characteristicsList: Array<Record<string, string | number | boolean>>;
  headerConfig?: {
    title?: string;
    characteristicKey: string;
  };
}

const ComparisonTable = ({
  className,
  characteristicsConfig,
  scrollBarColor,
  characteristicsList,
  headerConfig,
}: ComparisonTableProps): React.ReactElement => {
  return (
    <div
      className={clsx(
        className,
        styles.container,
        (scrollBarColor === 'blue' && styles.blue) ||
          (scrollBarColor === 'green' && styles.green) ||
          (scrollBarColor === 'grapefruit' && styles.grapefruit),
      )}
    >
      <div className={styles.characteristicsTitlesContainer}>
        {headerConfig && (
          <div className={styles.headerCell}>
            <span className={styles.headerTitle}>{headerConfig.title}</span>
          </div>
        )}
        {characteristicsConfig.map(characteristicConfig => (
          <>
            <div key={characteristicConfig.characteristicKey} className={styles.titleCell}>
              <span className={styles.title}>{characteristicConfig.title}</span>
            </div>
            <div className={styles.divider}></div>
          </>
        ))}
      </div>

      <div className={clsx(styles.entitiesContainer)}>
        {characteristicsList.map((characteristics, i) => (
          <div key={i} className={styles.entityColumn}>
            {headerConfig && (
              <div className={styles.headerCell}>
                <p className={styles.header}>{characteristics[headerConfig.characteristicKey]}</p>
              </div>
            )}
            {characteristicsConfig.map((characteristicConfig, i) => {
              const renderFunc =
                characteristicConfig.renderFunc || (characteristic => characteristic);
              return (
                <>
                  <div
                    key={characteristicConfig.characteristicKey}
                    className={styles.characteristicCell}
                  >
                    <div className={styles.characteristicWrapper}>
                      <p className={styles.characteristic}>
                        {renderFunc(characteristics[characteristicConfig.characteristicKey])}
                      </p>
                    </div>
                  </div>
                  <div className={styles.divider}></div>
                </>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComparisonTable;
