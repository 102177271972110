import React from 'react';
import { graphql } from 'gatsby';
import { ColorObject } from '../../graphql-fragments/ColorObject';
import { clsx } from '../../utils/utils';
import formImage from '../../images/formImage.png';

import Form from '../ui/Form';

import * as styles from './FormModule.module.scss';
import { CommonModuleProps } from '../../templates/Page';

export const FormModuleFragment = graphql`
  fragment FormModule on SanityFormModule {
    backgroundColor {
      ...ColorObject
    }
    decorationImageWord
  }
`;

export type FormModuleProps = {
  backgroundColor: ColorObject;
  decorationImageWord: 'contact' | 'feedback';
};

export function getModuleBgColor(props: FormModuleProps) {
  return props.backgroundColor?.title || 'white';
}

const FormModule = (props: FormModuleProps & CommonModuleProps): React.ReactElement => {
  const { backgroundColor, decorationImageWord, previousModuleBgColor } = props;

  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
        (backgroundColor?.title === 'blue' && styles.blue) ||
          (backgroundColor?.title === 'green' && styles.green) ||
          (backgroundColor?.title === 'grapefruit' && styles.grapefruit),
      )}
    >
      <div
        className={styles.container}
        data-aos="fade-left"
        data-aos-delay="0"
        data-aos-offset="0"
        data-aos-duration="1000"
      >
        <div className={styles.decorationImageWord}>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={formImage} alt="" />
          </div>
          <span
            className={clsx(styles.word, decorationImageWord === 'contact' ? styles.shortWord : '')}
          >
            {decorationImageWord}
          </span>
        </div>
        <div className={styles.form}>
          <Form />
        </div>
      </div>
    </section>
  );
};

export default FormModule;
