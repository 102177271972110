import React from 'react';
import { graphql } from 'gatsby';
import { clsx } from '../../utils/utils';

import * as styles from './FeaturedWordModule.module.scss';
import { CommonModuleProps } from '../../templates/Page';
import { replaceNewLinesWithBr } from '../../utils/sanity';

export const FeaturedWordModuleFragment = graphql`
  fragment FeaturedWordModule on SanityFeaturedWordModule {
    word
    backgroundImage {
      asset {
        url
      }
    }
    important
    title
    text
  }
`;

export interface FeaturedWordModuleProps {
  className?: string;
  word: string;
  important?: boolean;
  backgroundImage: {
    asset: {
      url: string;
    };
  };
  title?: string;
  text?: string;
}

export function getModuleBgColor(props: FeaturedWordModuleProps) {
  return 'white';
}

const FeaturedWordModule = (
  props: FeaturedWordModuleProps & CommonModuleProps,
): React.ReactElement => {
  const { className, word, important, backgroundImage, title, text, previousModuleBgColor } = props;
  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
      )}
    >
      <div className={clsx(styles.container, important ? styles.importantFeaturedWord : '')}>
        <span
          className={styles.word}
          style={{
            backgroundImage: `url(${backgroundImage.asset.url + '?w=1920&h=1080&fit=max'})`,
          }}
        >
          {word}
        </span>
        {(title || text) && (
          <div className={styles.content}>
            {title && <h2 className={styles.title}>{title}</h2>}
            {text && <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>}
          </div>
        )}
      </div>
    </section>
  );
};

export default FeaturedWordModule;
