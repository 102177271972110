import React, { useState } from 'react';
import usePortal from 'react-useportal';
import getYoutubeID from 'get-youtube-id';
import FsLightbox from 'fslightbox-react';
import { FaPlayCircle } from 'react-icons/fa';
import { truncateText, convertRToSuperscript, withDataLayer } from '../../utils/utils';

import ReactImageFallback from '../lib/ReactImageFallback';

import * as styles from './Video.module.scss';
import Button from './Button';

interface VideoProps {
  url: string;
  title?: string;
  date?: string;
  light?: boolean;
  maxWidth?: number;
  className?: string;
  textButton?: boolean;
}

const Video = ({
  url,
  title,
  date,
  light,
  maxWidth = 9999,
  className,
  textButton,
}: VideoProps): React.ReactElement => {
  const { Portal } = usePortal();

  const [lightboxToggler, setLightboxToggler] = useState(false);

  const id = getYoutubeID(url);
  if (!id) {
    throw new Error('Could not extract youtube id from url: ' + url);
  }
  const embedUrl = `https://www.youtube.com/embed/${id}`;

  const largeImgUrl = 'https://i.ytimg.com/vi/' + id + '/maxresdefault.jpg';
  const mediumImgUrl = 'https://i.ytimg.com/vi/' + id + '/sddefault.jpg';
  const smallImgUrl = 'https://i.ytimg.com/vi/' + id + '/hqdefault.jpg';

  const imgUrls = [smallImgUrl];
  if (maxWidth > 480) {
    imgUrls.push(mediumImgUrl);
  }
  if (maxWidth > 640) {
    imgUrls.push(largeImgUrl);
  }
  imgUrls.reverse();

  return (
    <div className={styles.card + ' ' + className}>
      <Portal>
        <FsLightbox
          toggler={lightboxToggler}
          sources={[
            // @ts-ignore
            <iframe
              key="video"
              className={styles.iframe}
              src={embedUrl + '?autoplay=1&enablejsapi=1'}
              allow="autoplay"
              enablejsapi="true"
            ></iframe>,
          ]}
        ></FsLightbox>
      </Portal>
      <div
        className={styles.thumbnailContainer}
        onClick={() => {
          setLightboxToggler(!lightboxToggler);
          withDataLayer(dataLayer => {
            dataLayer.push({
              event: 'hero-video-clicked',
            });
          });
        }}
      >
        <ReactImageFallback
          className={styles.image}
          src={imgUrls[0]}
          fallbackImage={imgUrls.slice(1)}
          alt=""
          getConsiderSuccessAsError={e => {
            if (e.path && e.path[0] && (e.path[0].width !== 120 || e.path[0].height !== 90)) {
              return false;
            }
            if (e.target && (e.target.width !== 120 || e.target.height !== 90)) {
              return false;
            }
            return true;
          }}
        ></ReactImageFallback>
        {textButton ? (
          <Button className={styles.button} rightIcon="play" color="grapefruit">
            Watch the video
          </Button>
        ) : (
          <FaPlayCircle className={styles.icon} />
        )}
        {!!date && <div className={styles.date}>{date.split('T')[0]}</div>}
      </div>
      {!!title &&
        (light ? (
          <span className={styles.titleLight} title={title}>
            {convertRToSuperscript(truncateText(title, 40))}
          </span>
        ) : (
          <h4 className={styles.title} title={title}>
            {convertRToSuperscript(truncateText(title, 60))}
          </h4>
        ))}
    </div>
  );
};

export default Video;
