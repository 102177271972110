import React from 'react';
import { useLocation } from '@reach/router';
import { FaSpinner, FaCheck, FaEnvelope } from 'react-icons/fa';
import { useFormField, useForm } from '../../utils/forms';
import InputField from './InputField';
import {
  clsx,
  loadFormFieldsFromLocalStorage,
  useStoreFormFieldsToLocalStorage,
  wait,
  withDataLayer,
} from '../../utils/utils';
import { useGlobalState } from '../../state/globalStateContext';

import * as styles from './Form.module.scss';
import { useTrackingData } from '../../utils/hooks';

const Form = (): React.ReactElement => {
  const location = useLocation();

  const trackingData = useTrackingData();

  const initialFieldValues = loadFormFieldsFromLocalStorage();
  const fieldsByName = {
    fullName: useFormField<string>(initialFieldValues.fullName || '', ['required']),
    email: useFormField<string>(initialFieldValues.email || '', ['required', 'email']),
    message: useFormField<string>(initialFieldValues.message || '', ['required']),
  } as const;
  useStoreFormFieldsToLocalStorage(fieldsByName);

  async function onSubmit() {
    if (process.env.NODE_ENV === 'development') {
      // Code to test form states (should be commented)
      // await wait(2000); // Case loading
      // throw new Error('Got response with status code 400'); // Case unknown error
      // throw new Error('Failed to fetch'); // Case network error
      // return true; // Case success
    }

    // const sendEmailResp = await fetch('/.netlify/functions/send-email-contact-form', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     page: location.pathname,
    //     name: fieldsByName.fullName.value,
    //     email: fieldsByName.email.value,
    //     message: fieldsByName.message.value,
    //   }),
    // });

    // if (sendEmailResp.status !== 200) {
    //   throw new Error('Got response with status code ' + sendEmailResp.status);
    // }

    const husbpotResp = await fetch('/.netlify/functions/submit-to-hubspot', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fullName: fieldsByName.fullName.value,
        email: fieldsByName.email.value,
        message: fieldsByName.message.value,
        queryString: window.location.search.substring(1),
        source_path: location.pathname,
        referrer_website: trackingData.websiteReferrer,
        utm_campaign: trackingData.utmCampaign,
        utm_content: trackingData.utmContent,
        utm_device: trackingData.utmDevice,
        utm_source: trackingData.utmSource,
        utm_target: trackingData.utmTarget,
        utm_medium: trackingData.utmMedium,
        utm_term: trackingData.utmTerm,
      }),
    });

    if (husbpotResp.status !== 200) {
      if (husbpotResp.status === 400) {
        const data = await husbpotResp.json();
        if (data.error === 'INVALID_EMAIL') {
          fieldsByName.email.setError('Invalid email format.');
          return false;
        } else {
          throw new Error('Got response with status code ' + husbpotResp.status);
        }
      } else {
        throw new Error('Got response with status code ' + husbpotResp.status);
      }
    }

    withDataLayer(dataLayer => {
      dataLayer.push({ event: 'form-submit', form: 'contact', urlPath: location.pathname });
    });
    return true;
  }

  const { getFieldProps, renderSubmitButton, renderFormMessage, submitState } = useForm({
    fieldsByName,
    onSubmit,
    translateFunction: key => {
      return {
        'form.required_field_error': 'This field is required',
        'form.invalid_email_error': 'Invalid email (e.g. email@example.com)',
        'form.network_error': 'Network failed to send your request.',
        'form.unknown_error': 'An unexpected error occured. Please try again later.',
        'form.success_message': 'Thank you for your message.',
      }[key];
    },
  });

  return (
    <div className={styles.formContainer}>
      <form name="contact" data-netlify="true" data-netlify-honeypot="hidden-field">
        <input
          className={styles.hiddenField}
          name="hidden-field"
          placeholder="language"
          autoComplete="language"
          type="text"
        />
        <div className={styles.inputContainer}>
          <div className={styles.nameInput}>
            <label className={styles.label} htmlFor="name-input">
              Name
            </label>
            <InputField
              className={styles.input + ' ' + styles.nameInput}
              type="text"
              id="name-input"
              name="name"
              placeholder="Type anything"
              helperTextClass={styles.helperText}
              {...getFieldProps(fieldsByName.fullName)}
            />
          </div>
          <label className={styles.label} htmlFor="email-input">
            Email
          </label>
          <InputField
            className={styles.input}
            type="email"
            id="email-input"
            name="email"
            placeholder="Type anything"
            helperTextClass={styles.helperText}
            {...getFieldProps(fieldsByName.email)}
          />

          <label className={styles.label} htmlFor="message-input">
            Message
          </label>
          <InputField
            className={styles.input + ' ' + styles.messageInput}
            textarea
            id="message-input"
            name="message"
            placeholder="Type anything"
            helperTextClass={styles.helperText}
            {...getFieldProps(fieldsByName.message)}
          />
        </div>
        {renderSubmitButton({
          labels: {
            ready: 'Send message',
            submitting: 'Sending',
            submitted: 'Sent',
          },
          btnClasses: {
            common: styles.submitButton,
            ready: styles.formReady,
            submitting: styles.formSubmitting,
            submitted: styles.formSubmitted,
          },
          childrenBefore:
            (submitState === 'ready' && (
              <FaEnvelope className={clsx(styles.buttonIcon)}></FaEnvelope>
            )) ||
            (submitState === 'submitting' && (
              <FaSpinner className={clsx(styles.buttonIcon, styles.buttonIconLoading)}></FaSpinner>
            )) ||
            (submitState === 'submitted' && (
              <FaCheck className={clsx(styles.buttonIcon, styles.buttonIconSubmitted)}></FaCheck>
            )),
        })}
        {renderFormMessage({
          styles: {
            formMessage: styles.formMessage,
            formMessageSuccess: styles.formMessageSuccess,
            formMessageError: styles.formMessageError,
          },
        })}
      </form>
    </div>
  );
};

export default Form;
