import React from 'react';
import { graphql } from 'gatsby';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx, getReferenceUrl } from '../../utils/utils';
import { CtaButton } from '../../graphql-fragments/CtaButton';
import { ColorObject } from '../../graphql-fragments/ColorObject';
import ButtonLink from '../ui/ButtonLink';
import Video from '../ui/Video';

import * as styles from './TextAndMediaModule.module.scss';
import { CommonModuleProps } from '../../templates/Page';

export const TextAndMediaModuleFragment = graphql`
  fragment TextAndMediaModule on SanityTextAndMediaModule {
    backgroundColor {
      ...ColorObject
    }
    title
    text
    mediaType
    image {
      asset {
        url
      }
      alt
    }
    mobileImage {
      asset {
        url
      }
      alt
    }
    videoUrl
    mediaSide
    ctaButton {
      ...CtaButton
    }
  }
`;

export type TextAndMediaModuleProps = {
  backgroundColor?: ColorObject;
  title?: string;
  text: string;
  mediaType: 'image' | 'video';
  image?: {
    asset: {
      url: string;
    };
    alt?: string;
  };
  mobileImage?: {
    asset: {
      url: string;
    };
    alt?: string;
  };
  videoUrl?: string;
  mediaSide: 'left' | 'right';
  ctaButton?: CtaButton;
};

export function getModuleBgColor(props: TextAndMediaModuleProps) {
  return props.backgroundColor?.title || 'white';
}

const TextAndMediaModule = (
  props: TextAndMediaModuleProps & CommonModuleProps,
): React.ReactElement => {
  const {
    backgroundColor,
    title,
    text,
    mediaType,
    image,
    mobileImage,
    videoUrl,
    mediaSide,
    ctaButton,
    previousModuleBgColor,
  } = props;
  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
        (backgroundColor?.title === 'blue' && styles.blue) ||
          (backgroundColor?.title === 'green' && styles.green) ||
          (backgroundColor?.title === 'grapefruit' && styles.grapefruit),
      )}
    >
      <div
        className={clsx(styles.container, mediaSide === 'left' && styles.invertedContainer)}
        data-aos="fade-left"
        data-aos-delay="0"
        data-aos-offset="0"
        data-aos-duration="1000"
      >
        <div className={styles.contentContainer}>
          {title && <h2 className={styles.title}>{title}</h2>}
          <p className={styles.paragraph}>{replaceNewLinesWithBr(text)}</p>
          {ctaButton && (
            <ButtonLink
              className={styles.button}
              to={getReferenceUrl(
                ctaButton.pageReference.__typename,
                ctaButton.pageReference.slug.current,
                ctaButton.pageReference.forceUrlEqualSlug,
              )}
              color={'dark-blue'}
              rightIcon={'arrow'}
            >
              {ctaButton.text}
            </ButtonLink>
          )}
        </div>
        {mediaType === 'image' && image && (
          <div className={clsx(styles.imageContainer, !mobileImage && styles.noMobileImage)}>
            <img
              className={styles.image}
              src={image.asset.url + '?w=1240&h=1240&fit=max'}
              alt={image.alt || ''}
            />
          </div>
        )}
        {mediaType === 'image' && mobileImage && (
          <div className={clsx(styles.mobileImageContainer)}>
            <img
              src={mobileImage.asset.url + '?w=1920&h=1920&fit=max'}
              alt={mobileImage.alt || ''}
              className={styles.image}
            />
          </div>
        )}
        {mediaType === 'video' && videoUrl && (
          <div className={styles.videoContainer}>
            <Video className={styles.video} url={videoUrl} />
          </div>
        )}
      </div>
    </section>
  );
};

export default TextAndMediaModule;
