// extracted by mini-css-extract-plugin
export var blue = "FormModule-module--blue--029ed";
export var container = "FormModule-module--container--503a0";
export var decorationImageWord = "FormModule-module--decorationImageWord--dfde1";
export var form = "FormModule-module--form--e1c36";
export var grapefruit = "FormModule-module--grapefruit--c9794";
export var green = "FormModule-module--green--89376";
export var image = "FormModule-module--image--63a60";
export var imageContainer = "FormModule-module--imageContainer--c3cfe";
export var section = "FormModule-module--section--07ca4";
export var sectionWithNoPadding = "FormModule-module--sectionWithNoPadding--ed539";
export var shortWord = "FormModule-module--shortWord--f21c8";
export var word = "FormModule-module--word--054bf";