// extracted by mini-css-extract-plugin
export var blue = "TextAndMediaModule-module--blue--e8e9c";
export var button = "TextAndMediaModule-module--button--c28cc";
export var container = "TextAndMediaModule-module--container--33d76";
export var contentContainer = "TextAndMediaModule-module--contentContainer--f68d1";
export var grapefruit = "TextAndMediaModule-module--grapefruit--427bf";
export var green = "TextAndMediaModule-module--green--0f067";
export var image = "TextAndMediaModule-module--image--ce398";
export var imageContainer = "TextAndMediaModule-module--imageContainer--0b91a";
export var invertedContainer = "TextAndMediaModule-module--invertedContainer--d9ecd";
export var mobileImageContainer = "TextAndMediaModule-module--mobileImageContainer--6acb0";
export var noMobileImage = "TextAndMediaModule-module--noMobileImage--45bf6";
export var paragraph = "TextAndMediaModule-module--paragraph--422e6";
export var section = "TextAndMediaModule-module--section--a4c8d";
export var sectionWithNoPadding = "TextAndMediaModule-module--sectionWithNoPadding--e853f";
export var title = "TextAndMediaModule-module--title--8abc4";
export var video = "TextAndMediaModule-module--video--df6b1";
export var videoContainer = "TextAndMediaModule-module--videoContainer--31240";