import React from 'react';
import ButtonLink from '../components/ui/ButtonLink';
import { COUNTRY_CODE_TO_COUNTRY_NAME } from '../constants';
import { getNumericIDFromSanityID } from './yotpo';

export function renderPrice(
  price?: '1' | '2' | '3',
  classes?: { price?: string; transparentPrice?: string },
) {
  if (!price) {
    return <span>N/A</span>;
  }
  const eurosByPrice = {
    1: '€',
    2: '€€',
    3: '€€€',
  };

  const transparentEurosByPrice = {
    1: '€€',
    2: '€',
    3: '',
  };

  return (
    <span>
      <span className={classes?.price}>{eurosByPrice[price]}</span>
      <span className={classes?.transparentPrice}>{transparentEurosByPrice[price]}</span>
    </span>
  );
}

export function renderPriceAsString(price?: '1' | '2' | '3') {
  if (!price) {
    return 'N/A';
  }
  const eurosByPrice = {
    1: '€',
    2: '€€',
    3: '€€€',
  };

  return eurosByPrice[price];
}

export function renderRating(entityId: string, classes?: { reviewStarsContainer?: string }) {
  return (
    <div
      className={
        'yotpo bottomLine reviewStarsContainer' +
        (classes?.reviewStarsContainer ? ' ' + classes?.reviewStarsContainer : '')
      }
      data-yotpo-product-id={getNumericIDFromSanityID(entityId)}
    ></div>
  );
}

export function renderCountries(countries: Array<string>) {
  return countries.map(countryCode => COUNTRY_CODE_TO_COUNTRY_NAME[countryCode]).join(', ');
}

export function renderArrayOfStrings(array: Array<string>) {
  return array.map(string => string).join(', ');
}

export function renderLawyerScale(
  scale: 'oneToFive' | 'fiveToTen' | 'tenToTwentyFive' | 'twentyFiveToFifty' | 'fiftyPlus',
) {
  const lawyerScale = {
    oneToFive: '1 - 5 partners',
    fiveToTen: '5-10 partners',
    tenToTwentyFive: '10-25 partners',
    twentyFiveToFifty: '25-50 partners',
    fiftyPlus: '50+ partners',
  };

  return lawyerScale[scale];
}

export function renderAreasOfExpertise(
  areasOfExpertise: Array<{
    title: string;
  }>,
) {
  return areasOfExpertise.map(expertise => expertise.title).join(', ');
}

export function renderBankScale(scale: 'boutiquePrivate' | 'national' | 'international') {
  const bankScale = {
    boutiquePrivate: 'Boutique/Private',
    national: 'National',
    international: 'International',
  };

  return bankScale[scale];
}

export function renderBoolean(boolean: boolean) {
  const booleanOptions = {
    true: 'Yes',
    false: 'No',
  };

  return booleanOptions[boolean + ''];
}

export function renderFundInvestmentSectors(
  sectors: Array<{
    title: string;
  }>,
) {
  return sectors.map(sector => sector.title).join(', ');
}

function commafy(num) {
  const str = num.toString().split('.');
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return str.join('.');
}

export function renderMinimumTicketSize(minimumTicketSize: number) {
  return '€' + commafy(minimumTicketSize);
}

export function renderTargetSize(targetSize: number) {
  return '€' + commafy(targetSize);
}

export function renderSetUpFee(setUpFee: {
  unit: 'percentage' | 'euro';
  number: number;
  includedOrAdded: 'included' | 'added';
  explainer?: string;
}) {
  const feeUnit = {
    euro: '€',
    percentage: '%',
  };

  return (
    <span>
      <p>
        {setUpFee.number}
        {feeUnit[setUpFee.unit]} {setUpFee.includedOrAdded}
      </p>
      {setUpFee.explainer && <p style={{ fontSize: 14 }}>{setUpFee.explainer}</p>}
    </span>
  );
}

export function renderSetUpFeeAsString(setUpFee: {
  unit: 'percentage' | 'euro';
  number: number;
  includedOrAdded: 'included' | 'added';
  explainer?: string;
}) {
  const feeUnit = {
    euro: '€',
    percentage: '%',
  };

  return (
    setUpFee.number +
    feeUnit[setUpFee.unit] +
    ' ' +
    setUpFee.includedOrAdded +
    ' ' +
    (setUpFee.explainer ? setUpFee.explainer : '')
  );
}

export function renderPercentageWithExplainer(fee?: { fee: number; explainer?: string }) {
  if (!fee) {
    return <span>N/A</span>;
  }
  return (
    <span>
      <p>{fee?.fee + '%'}</p>
      {fee?.explainer && <p style={{ fontSize: 14 }}>{fee?.explainer}</p>}
    </span>
  );
}

export function renderPercentageWithExplainerAsString(fee?: { fee: number; explainer?: string }) {
  if (!fee) {
    return 'N/A';
  }
  return (fee ? fee.fee + '%' : '') + ' ' + (fee.explainer ? fee.explainer : '');
}

export function renderMonthAndYear(date: string) {
  const month = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  };
  return month[date.slice(5, 7)] + ' ' + date.slice(0, 4);
}

export function renderFundDuration(fundDuration: number) {
  return fundDuration !== 1 ? fundDuration + ' years' : fundDuration + ' year';
}

export function renderPriceRange(min: number, max: number) {
  return `€${commafy(min)} - €${commafy(max)}`;
}

export function renderButton(url: string, classes?: { button: string }) {
  return (
    <ButtonLink className={classes?.button} to={url}>
      Know More
    </ButtonLink>
  );
}
