import React from 'react';
import { graphql } from 'gatsby';
import { clsx, getReferenceUrl } from '../../utils/utils';
import { CtaButton } from '../../graphql-fragments/CtaButton';

import * as styles from './FeaturedCardsModule.module.scss';
import { CommonModuleProps } from '../../templates/Page';
import FeaturedCard from '../ui/FeaturedCard';
import ButtonLink from '../ui/ButtonLink';

export const FeaturedCardsModuleFragment = graphql`
  fragment FeaturedCardsModule on SanityFeaturedCardsModule {
    title
    text
    cards {
      ctaButton {
        ...CtaButton
      }
      image {
        asset {
          url
        }
        alt
      }
    }
    ctaButton {
      ...CtaButton
    }
  }
`;

export type FeaturedCardsModuleProps = {
  title?: string;
  text?: string;
  cards: Array<{
    ctaButton: CtaButton;
    image: {
      asset: {
        url: string;
      };
      alt?: string;
    };
  }>;
  ctaButton?: CtaButton;
};

export function getModuleBgColor(props: FeaturedCardsModuleProps) {
  return 'white';
}

const FeaturedCardsModule = (
  props: FeaturedCardsModuleProps & CommonModuleProps,
): React.ReactElement => {
  const { title, text, cards, ctaButton, previousModuleBgColor } = props;
  const cardsPerRowClass = {
    1: 'cardsPerRow1',
    2: 'cardsPerRow2',
    3: 'cardsPerRow3',
    4: 'cardsPerRow4',
  }[cards.length === 3 || cards.length > 4 ? 3 : cards.length];

  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
      )}
    >
      <div className={styles.container}>
        <div
          data-aos="fade-right"
          data-aos-delay="0"
          data-aos-offset="0"
          data-aos-duration="1000"
          className={styles.content}
        >
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.paragraph}>{text}</p>
        </div>
        <div
          className={styles.contentContainer}
          data-aos="fade-up"
          data-aos-delay="100"
          data-aos-offset="0"
          data-aos-duration="1000"
        >
          <div className={styles.cardsContainer}>
            <div
              className={clsx(
                styles.cards,
                (cardsPerRowClass === 'cardsPerRow1' && styles.cardsPerRow1) ||
                  (cardsPerRowClass === 'cardsPerRow2' && styles.cardsPerRow2) ||
                  (cardsPerRowClass === 'cardsPerRow3' && styles.cardsPerRow3) ||
                  (cardsPerRowClass === 'cardsPerRow4' && styles.cardsPerRow4),
              )}
            >
              {cards.map((card, i) => (
                <FeaturedCard
                  key={i}
                  className={styles.card}
                  cardsPerRowClass={cardsPerRowClass}
                  imageUrl={card.image.asset.url + '?w=1240&h=1240&fit=max'}
                  alt={card.image.alt}
                  buttonText={card.ctaButton.text}
                  buttonUrl={getReferenceUrl(
                    card.ctaButton.pageReference.__typename,
                    card.ctaButton.pageReference.slug.current,
                    card.ctaButton.pageReference.forceUrlEqualSlug,
                  )}
                />
              ))}
            </div>
          </div>
          {ctaButton && (
            <ButtonLink
              className={styles.button}
              to={getReferenceUrl(
                ctaButton.pageReference.__typename,
                ctaButton.pageReference.slug.current,
                ctaButton.pageReference.forceUrlEqualSlug,
              )}
              secondary={true}
            >
              {ctaButton.text}
            </ButtonLink>
          )}
        </div>
      </div>
    </section>
  );
};

export default FeaturedCardsModule;
