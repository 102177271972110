import React, { useEffect } from 'react';
import AOS from 'aos';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import 'aos/dist/aos.css';
import { graphql } from 'gatsby';
import '../fragments';

import LayoutContainer from '../components/ui/LayoutContainer';
import PageSEO from '../components/PageSEO';
import { LocalizedSEO } from '../fragments';
import { ColorObject } from '../graphql-fragments/ColorObject';

import TextAndMediaModule, {
  TextAndMediaModuleProps,
  getModuleBgColor as getTextAndMediaModuleBgColor,
} from '../components/modules/TextAndMediaModule';
import ArticleModule, {
  ArticleModuleProps,
  getModuleBgColor as getArticleModuleBgColor,
} from '../components/modules/ArticleModule';
import ComparisonTableModule, {
  ComparisonTableModuleProps,
  getModuleBgColor as getComparisonTableModuleBgColor,
} from '../components/modules/ComparisonTableModule';
import EntityCardsModule, {
  EntityCardsModuleProps,
  getModuleBgColor as getEntityCardsModuleBgColor,
} from '../components/modules/EntityCardsModule';
import FeaturedCardsModule, {
  FeaturedCardsModuleProps,
  getModuleBgColor as getFeaturedCardsModuleBgColor,
} from '../components/modules/FeaturedCardsModule';

import FeaturedWordModule, {
  FeaturedWordModuleProps,
  getModuleBgColor as getFeaturedWordModuleBgColor,
} from '../components/modules/FeaturedWordModule';

import FormModule, {
  FormModuleProps,
  getModuleBgColor as getFormModuleBgColor,
} from '../components/modules/FormModule';

import * as styles from './Page.module.scss';
import Hero from '../components/ui/Hero';
import Button from '../components/ui/Button';

export const query = graphql`
  query GetPageById($id: String!) {
    sanityPage(id: { eq: $id }) {
      title
      slug {
        current
      }
      hero {
        topic
        title
        text
        images {
          asset {
            url
          }
          alt
        }
        mobileImage {
          asset {
            url
          }
          alt
        }
        color {
          ...ColorObject
        }
        withForm
      }
      content {
        __typename
        ...TextAndMediaModule
        ... on SanityTextAndMediaModule {
          disabled
        }
        ...ArticleModule
        ... on SanityArticleModule {
          disabled
        }
        ...ComparisonTableModule
        ... on SanityComparisonTableModule {
          disabled
        }
        ...EntityCardsModule
        ... on SanityEntityCardsModule {
          disabled
        }
        ...FeaturedCardsModule
        ... on SanityFeaturedCardsModule {
          disabled
        }
        ...FeaturedWordModule
        ... on SanityFeaturedCardsModule {
          disabled
        }
        ...FormModule
        ... on SanityFormModule {
          disabled
        }
      }
      seo {
        ...SEO
      }
    }
  }
`;

export interface CommonModuleProps {
  previousModuleBgColor: string | null;
}

type Module =
  | (TextAndMediaModuleProps & { __typename: 'SanityTextAndMediaModule' } & {
      disabled: boolean | null;
    })
  | (ArticleModuleProps & { __typename: 'SanityArticleModule' } & {
      disabled: boolean | null;
    })
  | (ComparisonTableModuleProps & { __typename: 'SanityComparisonTableModule' } & {
      disabled: boolean | null;
    })
  | (EntityCardsModuleProps & { __typename: 'SanityEntityCardsModule' } & {
      disabled: boolean | null;
    })
  | (FeaturedCardsModuleProps & { __typename: 'SanityFeaturedCardsModule' } & {
      disabled: boolean | null;
    })
  | (FeaturedWordModuleProps & { __typename: 'SanityFeaturedWordModule' } & {
      disabled: boolean | null;
    })
  | (FormModuleProps & { __typename: 'SanityFormModule' } & {
      disabled: boolean | null;
    });

export interface QueryHeroProps {
  topic?: string;
  title: string;
  text: string;
  images: Array<{
    asset: {
      url: string;
    };
    alt?: string;
  }>;
  mobileImage?: {
    asset: {
      url: string;
    };
    alt?: string;
  };
  color?: ColorObject;
  withForm?: boolean;
}
export interface QueryLandingPageHeroProps {
  title: string;
  text: string;
  videoUrl?: string;
  videoText?: string;
}

interface PageProps {
  data: {
    sanityPage: {
      title: string;
      slug: {
        current: string;
      };
      hero: QueryHeroProps;
      content: Array<Module>;
      seo: LocalizedSEO;
    };
  };
}

const Page = ({ data }: PageProps): React.ReactElement => {
  const { sanityPage: page } = data;

  useEffect(() => {
    AOS.init({
      once: true,
    });
    setTimeout(() => {
      AOS.refresh();
    }, 500);
  }, []);

  const enabledModules = page.content.filter(module => !module.disabled);
  const hasShareableArticle = page.content.find(
    module => module.__typename === 'SanityArticleModule' && module.showShareSection,
  );

  return (
    <LayoutContainer currentPage={page.slug.current} withHeroForm={page.hero?.withForm}>
      <PageSEO
        defaultTitle={
          page.slug.current === '/'
            ? ''
            : (data.sanityPage.seo && data.sanityPage.seo.title) || data.sanityPage.title
        }
        defaultDescription={data.sanityPage.seo && data.sanityPage.seo.description}
        defaultImageUrl={
          data.sanityPage.seo && data.sanityPage.seo.image && data.sanityPage.seo.image.asset.url
        }
        pageSEO={data.sanityPage.seo}
      />
      {page.hero && (
        <Hero
          title={page.hero.title}
          heroType={page.slug.current === '/' ? 'homepage' : 'genericPage'}
          text={page.hero.text}
          topic={page.hero.topic}
          images={page.hero.images.map(image => image)}
          mobileImage={page.hero.mobileImage}
          color={page.hero.color?.title}
          withForm={page.hero.withForm}
        >
          {hasShareableArticle && (
            <div className={styles.buttonsContainer}>
              <AnchorLink href={'#shareSection'} className={styles.fakeAnchor} offset="200">
                <Button className={styles.button} leftIcon={'share'}>
                  Share
                </Button>
              </AnchorLink>
            </div>
          )}
        </Hero>
      )}

      {!page.hero && page.title && (
        <div className={styles.pageTitleContainer}>
          <h1 className={styles.pageTitle}>{page.title}</h1>
        </div>
      )}

      {enabledModules.map((module, moduleIndex) => {
        const moduleKey = 'module-' + moduleIndex;

        // Defaults to white since we either have a hero (whose bottom part is white)
        // or we don't have a hero but have a title with a white background.
        let previousModuleBgColor = 'white';
        if (moduleIndex > 0) {
          const previousModule = enabledModules[moduleIndex - 1];
          if (previousModule.__typename === 'SanityTextAndMediaModule') {
            previousModuleBgColor = getTextAndMediaModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityArticleModule') {
            previousModuleBgColor = getArticleModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityComparisonTableModule') {
            previousModuleBgColor = getComparisonTableModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityEntityCardsModule') {
            previousModuleBgColor = getEntityCardsModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityFeaturedCardsModule') {
            previousModuleBgColor = getFeaturedCardsModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityFeaturedWordModule') {
            previousModuleBgColor = getFeaturedWordModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityFormModule') {
            previousModuleBgColor = getFormModuleBgColor(previousModule);
          } else {
            throw new Error(
              // @ts-ignore
              'Got unexpected previousModule with typename ' + previousModule.__typename,
            );
          }
        }

        const commonModuleProps: CommonModuleProps = {
          previousModuleBgColor,
        };

        if (module.__typename === 'SanityTextAndMediaModule') {
          return (
            <TextAndMediaModule
              key={moduleKey}
              {...module}
              {...commonModuleProps}
            ></TextAndMediaModule>
          );
        } else if (module.__typename === 'SanityArticleModule') {
          return (
            <ArticleModule
              key={moduleKey}
              {...module}
              {...commonModuleProps}
              withHeroForm={page.hero?.withForm}
            ></ArticleModule>
          );
        } else if (module.__typename === 'SanityComparisonTableModule') {
          return (
            <ComparisonTableModule
              key={moduleKey}
              {...module}
              {...commonModuleProps}
            ></ComparisonTableModule>
          );
        } else if (module.__typename === 'SanityEntityCardsModule') {
          return (
            <EntityCardsModule
              key={moduleKey}
              {...module}
              {...commonModuleProps}
            ></EntityCardsModule>
          );
        } else if (module.__typename === 'SanityFeaturedCardsModule') {
          return (
            <FeaturedCardsModule
              key={moduleKey}
              {...module}
              {...commonModuleProps}
            ></FeaturedCardsModule>
          );
        } else if (module.__typename === 'SanityFeaturedWordModule') {
          return (
            <FeaturedWordModule
              key={moduleKey}
              {...module}
              {...commonModuleProps}
            ></FeaturedWordModule>
          );
        } else if (module.__typename === 'SanityFormModule') {
          return <FormModule key={moduleKey} {...module} {...commonModuleProps}></FormModule>;
        } else {
          // @ts-ignore
          throw new Error('Got unexpected module with typename ' + module.__typename);
        }
      })}
    </LayoutContainer>
  );
};

export default Page;
