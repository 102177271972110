import React from 'react';
import { FaStar } from 'react-icons/fa';
import { Link } from 'gatsby';

import Button from './Button';
import { clsx } from '../../utils/utils';
import { getNumericIDFromSanityID } from '../../utils/yotpo';

import * as styles from './EntityCard.module.scss';

export interface EntityCardProps {
  entityId: string;
  hasReviews?: boolean;
  className?: string;
  imageUrl: string;
  name: string;
  introText: string;
  approved?: boolean;
  pageUrl: string;
}

const EntityCard = ({
  entityId,
  hasReviews,
  className,
  imageUrl,
  name,
  introText,
  approved,
  pageUrl,
}: EntityCardProps): React.ReactElement => {
  return (
    <Link to={pageUrl} className={clsx(className, styles.container)}>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={imageUrl} alt="" />
      </div>

      <div className={styles.nameAndApprovedLine}>
        <span className={styles.name}>{name}</span>
        {approved && (
          <div className={styles.approvedBox}>
            <FaStar className={styles.icon} />
            <p className={styles.approved}>Approved</p>
          </div>
        )}
      </div>
      {hasReviews && (
        <div
          className={'yotpo bottomLine reviewStarsContainer ' + styles.reviewStarsContainer}
          data-yotpo-product-id={getNumericIDFromSanityID(entityId)}
        ></div>
      )}
      <p className={styles.introText}>{introText}</p>
      <div className={styles.button}>
        <Button>Know More</Button>
      </div>
    </Link>
  );
};

export default EntityCard;
