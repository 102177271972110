import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ColorObject } from '../../graphql-fragments/ColorObject';
import { clsx, withDataLayer, urlJoin } from '../../utils/utils';
import { CSVLink } from 'react-csv';
import ComparisonTable from '../ui/ComparisonTable';
import { LawyerCharacteristics } from '../../graphql-fragments/Lawyer';
import { BankCharacteristics } from '../../graphql-fragments/Bank';
import { FundCharacteristics } from '../../graphql-fragments/Fund';

import {
  renderAreasOfExpertise,
  renderBankScale,
  renderBoolean,
  renderButton,
  renderCountries,
  renderFundDuration,
  renderFundInvestmentSectors,
  renderLawyerScale,
  renderMinimumTicketSize,
  renderMonthAndYear,
  renderPercentageWithExplainer,
  renderPercentageWithExplainerAsString,
  renderPrice,
  renderPriceAsString,
  renderRating,
  renderSetUpFee,
  renderSetUpFeeAsString,
  renderTargetSize,
} from '../../utils/characteristicsRenderFunctions';

import * as styles from './ComparisonTableModule.module.scss';
import { CommonModuleProps } from '../../templates/Page';
import { initYotpoWidgets } from '../../utils/yotpo';
import Button from '../ui/Button';
import { BANK_PREFIX_URL, FUND_PREFIX_URL, LAWYER_PREFIX_URL } from '../../constants';

const lawyerCharacteristicsConfig = [
  { title: 'Price', characteristicKey: 'price' },
  { title: 'Rating', characteristicKey: 'rating' },
  { title: 'Countries', characteristicKey: 'countriesWithOffices' },
  { title: 'Scale', characteristicKey: 'scale' },
  { title: 'Expertise', characteristicKey: 'expertise' },
  { characteristicKey: 'pageUrl' },
];

const bankCharacteristicsConfig = [
  { title: 'Price', characteristicKey: 'price' },
  { title: 'Rating', characteristicKey: 'rating' },
  { title: 'Countries', characteristicKey: 'countriesWithOffices' },
  { title: 'Scale', characteristicKey: 'scale' },
  {
    title: 'Retail Branches',
    characteristicKey: 'hasRetailBranches',
  },
  { characteristicKey: 'pageUrl' },
];

const fundCharacteristicsConfig = [
  {
    title: 'Investment Sectors',
    characteristicKey: 'fundInvestmentSectors',
  },
  {
    title: 'Strategy Overview',
    characteristicKey: 'strategyOverview',
  },
  {
    title: 'Minimum Ticket Size',
    characteristicKey: 'minimumTicketSize',
  },
  {
    title: 'First Close Passed',
    characteristicKey: 'firstClosePassed',
  },
  { title: 'Set Up Fee', characteristicKey: 'setUpFee' },
  { title: 'Management Fee', characteristicKey: 'managementFee' },
  { title: 'Performance Fee', characteristicKey: 'performanceFee' },
  {
    title: 'Target Annual Returns',
    characteristicKey: 'targetAnnualReturns',
  },
  {
    title: 'Target Returns Upon Exit',
    characteristicKey: 'targetReturnsUponExit',
  },
  {
    title: 'Subscription Deadline',
    characteristicKey: 'subscriptionDeadline',
  },
  { title: 'Target Size', characteristicKey: 'targetSize' },
  {
    title: 'Month Established',
    characteristicKey: 'monthEstablished',
  },
  { title: 'Fund Duration', characteristicKey: 'fundDuration' },
  { title: 'FATCA & PFIC', characteristicKey: 'fatcaAndPfic' },
  { characteristicKey: 'pageUrl' },
];

const realEstateProjectCharacteristicsConfig = [
  { title: 'Developer company name', characteristicKey: 'developerCompanyName' },
  { title: 'Real estate market sectors', characteristicKey: 'realEstateMarketSectors' },
  {
    title: 'Total number of units in the project',
    characteristicKey: 'totalNumberOfUnitsInTheProject',
  },
  { title: 'What you purchase', characteristicKey: 'whatYouPurchase' },
  { title: 'Unit price range', characteristicKey: 'unitPriceRange' },
  { title: 'Current project state', characteristicKey: 'currentProjectState' },
  { title: 'Who manages the property', characteristicKey: 'whoManagesTheProperty' },
  { title: 'Management fee', characteristicKey: 'managementFee' },
  { title: 'Target annual returns', characteristicKey: 'targetAnnualReturns' },
  { title: 'Fixed return', characteristicKey: 'fixedReturn' },
  { title: 'Buyback options', characteristicKey: 'buybackOptions' },
  { title: 'Which category of Golden Visa', characteristicKey: 'whichGVcategories' },
  { characteristicKey: 'pageUrl' },
];

const entityToCharacteristicsConfig = {
  lawyer: lawyerCharacteristicsConfig,
  bank: bankCharacteristicsConfig,
  fund: fundCharacteristicsConfig,
  realEstateProject: realEstateProjectCharacteristicsConfig,
};

export const ComparisonTableModuleFragment = graphql`
  fragment ComparisonTableModule on SanityComparisonTableModule {
    backgroundColor {
      ...ColorObject
    }
    entity
    title
    text
  }
`;

export type ComparisonTableModuleProps = {
  backgroundColor: ColorObject;
  entity: 'lawyer' | 'bank' | 'fund';
  title: string;
  text: string;
  onExportTableClick: () => void;
};

interface QueryData {
  allSanityLawyer: {
    nodes: Array<LawyerCharacteristics>;
  };
  allSanityBank: {
    nodes: Array<BankCharacteristics>;
  };
  allSanityFund: {
    nodes: Array<FundCharacteristics>;
  };
}

export function getModuleBgColor(props: ComparisonTableModuleProps) {
  return props.backgroundColor?.title || 'white';
}

const ComparisonTableModule = (
  props: ComparisonTableModuleProps & CommonModuleProps,
): React.ReactElement => {
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    setHasLoaded(true);
  }, []);

  const { backgroundColor, entity: entityType, title, text, previousModuleBgColor } = props;
  const data: QueryData = useStaticQuery(
    graphql`
      {
        allSanityLawyer {
          nodes {
            ...Lawyer
          }
        }
        allSanityBank {
          nodes {
            ...Bank
          }
        }
        allSanityFund {
          nodes {
            ...Fund
          }
        }
      }
    `,
  );

  const allLawyers = data.allSanityLawyer.nodes;
  const allBanks = data.allSanityBank.nodes;
  const allFunds = data.allSanityFund.nodes;

  useEffect(() => {
    initYotpoWidgets();
  }, []);

  const entities = {
    lawyer: allLawyers,
    bank: allBanks,
    fund: allFunds,
  }[entityType];

  let characteristicsList;

  if (entityType === 'lawyer') {
    characteristicsList = (entities as Array<LawyerCharacteristics>).map(entity => ({
      ...entity,
      price: renderPrice(entity.price, styles),
      rating: renderRating(entity._id, styles),
      countriesWithOffices: renderCountries(entity.countriesWithOffices),
      scale: renderLawyerScale(entity.scale),
      expertise: renderAreasOfExpertise(entity.areasOfExpertise),
      pageUrl: renderButton(urlJoin(LAWYER_PREFIX_URL, entity.slug.current), styles),
    }));
  } else if (entityType === 'bank') {
    characteristicsList = (entities as Array<BankCharacteristics>).map(entity => ({
      ...entity,
      price: renderPrice(entity.price, styles),
      rating: renderRating(entity._id, styles),
      countriesWithOffices: renderCountries(entity.countriesWithOffices),
      scale: renderBankScale(entity.scale),
      hasRetailBranches: renderBoolean(entity.hasRetailBranches),
      pageUrl: renderButton(urlJoin(BANK_PREFIX_URL, entity.slug.current), styles),
    }));
  } else if (entityType === 'fund') {
    characteristicsList = (entities as Array<FundCharacteristics>).map(entity => ({
      ...entity,
      fundInvestmentSectors: renderFundInvestmentSectors(entity.fundInvestmentSectors),
      minimumTicketSize: renderMinimumTicketSize(entity.minimumTicketSize),
      firstClosePassed: renderBoolean(entity.firstClosePassed),
      setUpFee: renderSetUpFee(entity.setUpFee),
      managementFee: renderPercentageWithExplainer(entity.managementFee),
      performanceFee: renderPercentageWithExplainer(entity.performanceFee),
      targetAnnualReturns: renderPercentageWithExplainer(entity.targetAnnualReturns),
      targetReturnsUponExit: renderPercentageWithExplainer(entity.targetReturnsUponExit),
      subscriptionDeadline: renderMonthAndYear(entity.subscriptionDeadline),
      targetSize: renderTargetSize(entity.targetSize),
      monthEstablished: renderMonthAndYear(entity.monthEstablished),
      fundDuration: renderFundDuration(entity.fundDuration),
      fatcaAndPfic: renderBoolean(entity.fatcaAndPfic),
      pageUrl: renderButton(urlJoin(FUND_PREFIX_URL, entity.slug.current), styles),
    }));
  } else {
    throw new Error('Unexpected entity ' + entityType);
  }

  let csvFileName;
  let csvHeaders;
  let csvData;

  if (entityType === 'lawyer') {
    csvFileName = 'GoldenVisaCommunity-Lawyers.csv';
    csvHeaders = [
      { label: 'Name', key: 'name' },
      { label: 'Price', key: 'price' },
      { label: 'Countries With Offices', key: 'countriesWithOffices' },
      { label: 'Scale', key: 'scale' },
      { label: 'Areas Of Expertise', key: 'areasOfExpertise' },
    ];
    csvData = (entities as Array<LawyerCharacteristics>).map(entity => ({
      ...entity,
      price: renderPriceAsString(entity.price),
      countriesWithOffices: renderCountries(entity.countriesWithOffices),
      scale: renderLawyerScale(entity.scale),
      areasOfExpertise: renderAreasOfExpertise(entity.areasOfExpertise),
    }));
  } else if (entityType === 'bank') {
    csvFileName = 'GoldenVisaCommunity-Banks.csv';
    csvHeaders = [
      { label: 'Name', key: 'name' },
      { label: 'Price', key: 'price' },
      { label: 'Countries With Offices', key: 'countriesWithOffices' },
      { label: 'Scale', key: 'scale' },
      { label: 'Has Retail Branches', key: 'hasRetailBranches' },
    ];
    csvData = (entities as Array<BankCharacteristics>).map(entity => ({
      ...entity,
      price: renderPriceAsString(entity.price),
      countriesWithOffices: renderCountries(entity.countriesWithOffices),
      scale: renderBankScale(entity.scale),
      hasRetailBranches: renderBoolean(entity.hasRetailBranches),
    }));
  } else if (entityType === 'fund') {
    csvFileName = 'GoldenVisaCommunity-Funds.csv';
    csvHeaders = [
      { label: 'Name', key: 'name' },
      { label: 'Investment Sectors', key: 'investmentSectors' },
      { label: 'Strategy Overview', key: 'strategyOverview' },
      { label: 'Minimum Ticket Size', key: 'minimumTicketSize' },
      { label: 'First Close Passed', key: 'firstClosePassed' },
      { label: 'Set Up Fee', key: 'setUpFee' },
      { label: 'Management Fee', key: 'managementFee' },
      { label: 'Performance Fee', key: 'performanceFee' },
      { label: 'Target Annual Returns', key: 'targetAnnualReturns' },
      { label: 'Target Returns Upon Exit', key: 'targetReturnsUponExit' },
      { label: 'Subscription Deadline', key: 'subscriptionDeadline' },
      { label: 'Target Size', key: 'targetSize' },
      { label: 'Month Established', key: 'monthEstablished' },
      { label: 'Fund Duration', key: 'fundDuration' },
      { label: 'FATCA & PFIC', key: 'fatcaAndPfic' },
    ];
    csvData = (entities as Array<FundCharacteristics>).map(entity => ({
      ...entity,
      investmentSectors: renderFundInvestmentSectors(entity.fundInvestmentSectors),
      strategyOverview: entity.strategyOverview,
      minimumTicketSize: renderMinimumTicketSize(entity.minimumTicketSize),
      firstClosePassed: renderBoolean(entity.firstClosePassed),
      setUpFee: renderSetUpFeeAsString(entity.setUpFee),
      managementFee: renderPercentageWithExplainerAsString(entity.managementFee),
      performanceFee: renderPercentageWithExplainerAsString(entity.performanceFee),
      targetAnnualReturns: renderPercentageWithExplainerAsString(entity.targetAnnualReturns),
      targetReturnsUponExit: renderPercentageWithExplainerAsString(entity.targetReturnsUponExit),
      subscriptionDeadline: renderMonthAndYear(entity.subscriptionDeadline),
      targetSize: renderTargetSize(entity.targetSize),
      monthEstablished: renderMonthAndYear(entity.monthEstablished),
      fundDuration: renderFundDuration(entity.fundDuration),
      fatcaAndPfic: renderBoolean(entity.fatcaAndPfic),
    }));
  } else {
    throw new Error('Unexpected entity ' + entityType);
  }

  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
        previousModuleBgColor === 'white' && styles.sectionWithNoPaddingMobile,
        (backgroundColor?.title === 'blue' && styles.blue) ||
          (backgroundColor?.title === 'green' && styles.green) ||
          (backgroundColor?.title === 'grapefruit' && styles.grapefruit),
      )}
    >
      <div
        className={styles.container}
        data-aos="fade-left"
        data-aos-delay="0"
        data-aos-offset="0"
        data-aos-duration="1000"
      >
        <div className={styles.contentContainer}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.paragraph}>{text}</p>
          {hasLoaded && (
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={csvFileName}
              className={styles.exportTable}
            >
              <Button
                color={'dark-blue'}
                className={styles.button}
                onClick={() => {
                  withDataLayer(dataLayer => {
                    dataLayer.push({
                      event: 'export-comparison-table',
                      entity: entityType,
                    });
                  });
                }}
              >
                Export table
              </Button>
            </CSVLink>
          )}
          <ComparisonTable
            className={styles.table}
            characteristicsConfig={entityToCharacteristicsConfig[entityType]}
            scrollBarColor={backgroundColor.title}
            characteristicsList={characteristicsList}
            headerConfig={{
              characteristicKey: 'name',
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default ComparisonTableModule;
